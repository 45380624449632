import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Palette'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Palette`}</h1>
    <p>{`The theme palette provides a collection of common color settings for the UI.`}</p>
    <p>{`There are some predefined "basic" and "elevation" colors. By default, they are
set up with predefined color values, but these values are not intended to be
used in production, but rather configured according to the project needs,
since they do not follow a proper visual guideline.`}</p>
    <h2>{`Basic Colors`}</h2>
    <p>{`The palette is composed by the following basic colors:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`primary`}</inlineCode>{` - Primary interface component elements.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`secondary`}</inlineCode>{` - Complementary color.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`success`}</inlineCode>{` - Successful messages. It should tend to be green.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`error`}</inlineCode>{` - Error messages. It should tend to be red.`}</li>
    </ul>
    <p>{`Each color has the following variations:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`main`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`dark1`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`dark2`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`dark3`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`light1`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`light2`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`light3`}</inlineCode></li>
    </ul>
    <p>{`Only the `}<inlineCode parentName="p">{`main`}</inlineCode>{` color is required. If the other variations are not configured,
they are calculated based on the palette setting `}<inlineCode parentName="p">{`tonalOffset`}</inlineCode>{`.`}</p>
    <p><inlineCode parentName="p">{`tonalOffset`}</inlineCode>{` is the color tone variation factor. It is used to calculate dark
and light variations. From `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`1`}</inlineCode>{`, by default `}<inlineCode parentName="p">{`0.1`}</inlineCode>{`. The higher the
number, the stronger the effect.`}</p>
    <p>{`An example of configuring the `}<inlineCode parentName="p">{`primary`}</inlineCode>{` color with all its variations so no
calculations are made:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { createTheme } from '@arwes/design';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0ff',
      dark1: '#0cc',
      dark2: '#099',
      dark3: '#066',
      light1: '#3ff',
      light2: '#6ff',
      light3: '#9ff'
    }
  }
});
`}</code></pre>
    <p>{`An example of configuring the `}<inlineCode parentName="p">{`secondary`}</inlineCode>{` color with only the main color,
leaving the remaining variations to be calculated with custom the tonal offset:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const theme = createTheme({
  palette: {
    tonalOffset: 0.15,
    secondary: {
      main: '#f0f'
    }
  }
});
`}</code></pre>
    <p>{`The resulting theme palette will have all the color variations.`}</p>
    <p>{`The theme palette can be accessed like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const theme = createTheme();

theme.palette.primary.main; // '#0ff'
theme.palette.primary.dark1; // '#0cc'
// ...
`}</code></pre>
    <p>{`Custom basic colors can be created using the utility `}<inlineCode parentName="p">{`createThemePaletteBasic`}</inlineCode>{` like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { createTheme, createThemePaletteBasic } from '@arwes/design';

const theme = createTheme({
  palette: {
    yourOwnBasicPalette: createThemePaletteBasic({ main: '#00f' }, 0.1)
  }
});

theme.palette.yourOwnBasicPalette.main; // '#00f'
// ...
`}</code></pre>
    <h2>{`Elevation Colors`}</h2>
    <p>{`There are some colors used as "levels" or "elevations" to delimit or prioritize
surfaces. The elevation effect is basically a color which becomes lighter or
darker progressively for different surfaces according to the color luminosity.`}</p>
    <p>{`The palette is composed by the following elevation effect colors:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`neutral`}</inlineCode>{` - For general surface and containers backgrounds.`}</li>
    </ul>
    <p>{`The neutral color does not have variations automatically generated, but rather
they are calculated when requested.`}</p>
    <p>{`To calculate the elevation of a color, the `}<inlineCode parentName="p">{`elevationOffset`}</inlineCode>{` setting is used.`}</p>
    <p><inlineCode parentName="p">{`elevationOffset`}</inlineCode>{` is the tone offset to darken or lighten according to the
color luminosity. It goes from `}<inlineCode parentName="p">{`0`}</inlineCode>{` to `}<inlineCode parentName="p">{`1`}</inlineCode>{`, by default `}<inlineCode parentName="p">{`0.025`}</inlineCode>{`.`}</p>
    <p>{`An example of defining the neutral color:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const theme = createTheme({
  palette: {
    elevationOffset: 0.025,
    neutral: {
      main: '#111'
    }
  }
});
`}</code></pre>
    <p>{`Then the color elevation can be calculated based on a multiplier number of the
tone offset:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// Since it is a "dark" color, it is lightened.
theme.palette.neutral.elevate(0); // '#111' (The unchanged color)
theme.palette.neutral.elevate(1); // '#171717'
theme.palette.neutral.elevate(2); // '#1e1e1e'
theme.palette.neutral.elevate(3); // '#242424'
// ...
`}</code></pre>
    <p>{`Custom elevation colors can be created using the utility `}<inlineCode parentName="p">{`createThemePaletteElevation`}</inlineCode>{`
like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { createTheme, createThemePaletteElevation } from '@arwes/design';

const theme = createTheme({
  palette: {
    yourOwnElevationPalette: createThemePaletteElevation('#101', 0.025)
  }
});

theme.palette.yourOwnElevationPalette.elevate(0); // '#101'
// ...
`}</code></pre>
    <h2>{`Extra Settings`}</h2>
    <p>{`The palette can be setup with any extra color settings with no enforced
structure as needed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      